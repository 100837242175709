<template>
  <router-view></router-view>
</template>

<script>
import FlightRedirectMixins from '@/modules/flights/mixins/FlightRedirectMixins';
import InformationMixins from './mixins/InformationMixins.vue';
import * as Sentry from '@sentry/vue';

export default {
  name: 'InformationPage',
  mixins: [FlightRedirectMixins, InformationMixins],
  created() {
    Sentry.setTag('module', 'information');
  },
  methods: {
    goToErrorPage() {
      const notFoundError =
        this.informationCategoriesError &&
        this.informationCategoriesError.status >= 400;
      const internalServerError =
        this.informationCategoriesError &&
        this.informationCategoriesError.status >= 500;

      if (internalServerError) this.$router.replace('/500');
      else if (notFoundError) this.$router.replace('/404');
    },
  },
  watch: {
    informationCategoriesError(val) {
      if (!!val) {
        this.goToErrorPage();
      }
    },
  },
};
</script>

<style scoped></style>
